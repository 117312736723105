import {customElement, property, queryAssignedElements, state} from 'lit/decorators.js';
import {html, LitElement, nothing} from 'lit';
import { style } from './pagination.styles'
import {SbkPaginationItem} from "@/components/pagination-item/pagination-item";

@customElement('sbk-pagination')
export class SbkPagination extends LitElement {

  @property()
  label: string = '';

  @property({attribute: 'next-label'})
  nextLabel: string = '';

  @property({attribute: 'prev-label'})
  prevLabel: string = '';

  @property({type: Boolean, attribute: 'is-ajax'})
  isAjax = false;
 
  @state()
  private currentItem?: SbkPaginationItem;

  @state()
  private totalPages?: number;
  
  @state()
  prevLink: string = '';
  
  @state()
  nextLink: string = '';

  @queryAssignedElements({selector: 'sbk-pagination-item'})
  _paginationItems!: Array<SbkPaginationItem>


  static get styles() {
    return style;
  }

  render() {
    if (this.totalPages && this.totalPages < 1) {
      return '';
    }
    return html`
      <nav role="navigation" aria-label=${this.label}>
        <ul class="pagination">
          ${this.renderPrevButton()}
          <slot @slotchange=${this._handleSlotChange}></slot>
          ${this.renderNextButton()}
        </ul>
      </nav>
    `;
  }

  renderPrevButton() {
    if (this.currentItem?.page === 1) {
      return html`${nothing}`;
    }
    return html`
      <sbk-pagination-item variant="icon-only"
                           icon="chevron-left"
                           ?is-ajax=${this.isAjax}
                           href=${this.prevLink}>${this.prevLabel}</sbk-pagination-item>
    `;
  }

  renderNextButton() {
    if (this.currentItem?.page === this.totalPages) {
      return html`${nothing}`;
    }
    return html`
      <sbk-pagination-item variant="icon-only" 
                           icon="chevron-right"
                           ?is-ajax=${this.isAjax}
                           href=${this.nextLink}>${this.nextLabel}</sbk-pagination-item>
    `;
  }

  _handleSlotChange() {
    this.totalPages = this._paginationItems.length;
    this._paginationItems && this._paginationItems.forEach(paginationItem => {
      if (paginationItem.active) {
        this.currentItem = paginationItem;
      }
    });
    if (this.currentItem) {
      this._paginationItems && this._paginationItems.forEach(paginationItem => {
        if (this.currentItem?.page && ((this.currentItem?.page < 5 && paginationItem.page <= 6) || (paginationItem.page >= (this.currentItem?.page - 2) && paginationItem.page <= (this.currentItem?.page + 3)))) {
          paginationItem.hidden = false;
        } else {
          paginationItem.hidden = true
        }
      });
      const indexCurrentItem = this._paginationItems.indexOf(this.currentItem);
      if (this.currentItem.page > 1) {
        this.prevLink = this._paginationItems[indexCurrentItem - 1].href;
      }
      if (this.currentItem.page < this.totalPages) {
        this.nextLink = this._paginationItems[indexCurrentItem + 1].href;
      }
    }
  }
}