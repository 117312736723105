import {css} from "lit";


export const selectOptionStyles = css`
  :host {
    --select-option__color: var(--_select-option__color, var(--sbkSelectColorFgColorOnLightDefault));
    --select-option__color--active: var(--_select-option__color--active, var(--sbkSelectColorFgColorOnDarkEnabled));
    --select-option__color--disabled: var(--_select-option__color--disabled, var(--sbkSelectColorFgColorOnLightDisabled));

    --select-option__bg: var(--_select-option__bg, var(--sbkSelectColorBgColorOnLightFlyoutEnabled));
    --select-option__bg--active: var(--_select-option__bg--active, var(--sbkSelectColorBgColorOnLightFlyoutActive));
  }
  
  * {
    box-sizing: border-box;
  }

  .select-option {
    cursor: pointer;
    font: var(--sbkSelectTypographyFlyoutSm);
    color: var(--select-option__color);
    padding: var(--sbkSelectSpacingXs);
  }

  .select-option--empty-option {
    color: var(--select-option__color--disabled)
  }

    @media (hover: hover) and (pointer: fine) {
        .select-option:hover {
            background-color: var(--select-option__bg--active);
            color: var(--select-option__color--active);
        }
    }

  :host(.select-option--current) .select-option {
    background-color: var(--select-option__bg--active);
    color: var(--select-option__color--active);
  }
  
  .select-option__description {
    margin-top: var(--sbkSelectSpacing2xs);
    font: var(--sbkSelectTypographyFlyoutXs);
  }
`;
