import { sbkCoreBreakpointMd } from "@/tokens/variables";
import {css, unsafeCSS} from "lit";
const breakpointMd = unsafeCSS(`${sbkCoreBreakpointMd}`);

export const style = css`
 :host {
            
      --form-steps__fg-color-enabled: var(--sbkFormStepsFgColorOnLightEnabled);
      --form-steps__fg-color-hover: var(--sbkFormStepsFgColorOnLightHover);
      --form-steps__fg-color-active: var(--sbkFormStepsFgColorOnLightActive);
      --form-steps__fg-color-disabled: var(--sbkFormStepsFgColorOnLightDisabled);
        --form-steps-button__fg-color-active: var(--sbkFormStepsFgColorOnDarkActive);

      --form-steps__bg-color-enabled: var(--sbkFormStepsBgColorOnLightEnabled);
      --form-steps__bg-color-hover: var(--sbkFormStepsBgColorOnLightHover);
      --form-steps__bg-color-active: var(--sbkFormStepsBgColorOnLightActive);
      --form-steps__bg-color-disabled: var(--sbkFormStepsBgColorOnLightDisabled);

      --form-steps__border-enabled: var(--sbkFormStepsBorderOnLightEnabled);
      --form-steps__border-hover: var(--sbkFormStepsBorderOnLightHover);
      --form-steps__border-active: var(--sbkFormStepsBorderOnLightActive);
      --form-steps__border-disabled: var(--sbkFormStepsBorderOnLightDisabled);
      --form-steps__border-focus: var(--sbkFormStepsBorderOnLightFocus);

      --form-steps__border-radius: var(--sbkFormStepsBorderRadiusFocusLabel);
      --form-steps__border-gap: var(--sbkFormStepsBorderGapFocus);
      --form-steps__max-height: var(--sbkFormStepsSizingMaxHeight);
      --form-steps__regular-font: var(--sbkFormStepsRegularSm);
      --form-steps__underlined-font: var(--sbkFormStepsUnderlinedSm);
      --form-steps__spacing-indicator-top-bottom: var(--sbkFormStepsSpacingIndicatorInsetTopBottom);
      --form-steps__spacing-indicator-left-right: var(--sbkFormStepsSpacingIndicatorInsetLeftRight);
      --form-steps__spacing-step-item-gap-label-right: var(--sbkFormStepsSpacingStepItemGapLabelRight);
      --form-steps__spacing-step-item-horizontal-gap: var(--sbkFormStepsSpacingStepItemHorizontalGap);

      
  }

  .form-step-item {
    display: flex;
    gap: var(--form-steps__spacing-step-item-gap-label-right);
    align-items: center;
    justify-content: center;
    padding: var(--form-steps__spacing-indicator-top-bottom) var(--form-steps__spacing-indicator-left-right);
    border-radius: var(--form-steps__border-radius);
    cursor: pointer;
    transition: background-color 0.3s, border-color 0.3s;
    outline-offset: var(--form-steps__border-gap);
    outline: 2px solid transparent;
    font: var(--sbkFormStepsRegularSm);
  }
    
        :host(:focus-visible) .button {
            outline: var(--form-steps__border-focus);
        }
        
        .form-step-item--active {
          .button {
            background-color: var(--form-steps__bg-color-active);
            color: var(--form-steps-button__fg-color-active);
            border-color: var(--form-steps__bg-color-active);
          }
          .text {
            color: var(--form-steps__fg-color-active);;
          }
        }
  
  .form-step-item--done .button {
            background-color: transparent;
            color: var(--form-steps__fg-color-enabled);
            border-color: var(--form-steps__fg-color-enabled);
        }

  .form-step-item--disabled {
            cursor: not-allowed;
            pointer-events: none;
            .text, .button {
               cursor: not-allowed;
               pointer-events: none;
                color: var(--form-steps__fg-color-disabled);
            }
        } 
  
  @media (hover: hover) and (pointer: fine) {
      :host(:hover) > :not(.form-step-item--disabled) {
          .button {
              border-color: var(--form-steps__border-hover);
              color: var(--form-steps__fg-color-hover);
              background-color: var(--form-steps__bg-color-hover);
          }

          .text {
            color: var(--form-steps__fg-color-hover);
              font: var(--sbkFormStepsUnderlinedSm);
              text-decoration: underline;
          }
      }
  }
        
  
      .button {
        display: flex;
        flex: 1 0 var(--form-steps__max-height);
        justify-content: center;
        align-items: center;
        height: var(--form-steps__max-height);
        width: var(--form-steps__max-height);
        border-radius: 100%;
        border: var(--form-steps__border-disabled);
        color: var(--form-steps__fg-color-disabled);
        cursor: pointer;
        transition: background-color 0.3s, border-color 0.3s;
        outline-offset: var(--form-steps__border-gap);
        outline: 2px solid transparent;
      }
  
      .text {
        display: none;
        color: var(--form-steps__border-enabled);
        outline-offset: var(--form-steps__border-gap);
        outline: 2px solid transparent;
      }
  
    @media screen and (min-width: ${breakpointMd}) {
        .text {
          display: block;
        }
        :host(:focus-visible) .text{
            border-radius: var(--form-steps__border-radius);
            outline: var(--form-steps__border-focus);
        }
        :host(:focus-visible) .button{
            outline: none;
        }
      }
  `;