import { css } from 'lit';

export const style = css`
  .pagination {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: var(--sbkPaginationSpacing3xs);
  }

  ul {
    list-style: none;
    padding: 0;
  }
    
`;