// Vendor
import 'vite/modulepreload-polyfill'

import '@/styles/styles.scss'

// Components
import '@/components/a-z-list/a-z-list'
import '@/components/button/button'
import '@/components/icon/icon'
import '@/components/round-button/round-button'
import '@/components/accordion/accordion'
import '@/components/banner/banner'
import '@/components/checkbox/checkbox'
import '@/components/checkbox-group/checkbox-group'
import '@/components/consultant-search/consultant-search'
import '@/components/data-protection/data-protection'
import '@/components/divider/divider'
import '@/components/summary-page/summary-page'
import '@/components/form/form'
import '@/components/form-control-dependency/form-control-dependency'
import '@/components/form-steps/form-steps'
import '@/components/form-step-item/form-step-item'
import '@/components/image-sources/image-sources'
import '@/components/input/input'
import '@/components/headline-link/headline-link'
import '@/components/image/image'
import '@/components/likes/likes'
import '@/components/link/link'
import '@/components/list/list'
import '@/components/map/map'
import '@/components/modal/modal'
import '@/components/pagination/pagination'
import '@/components/pagination-item/pagination-item'
import '@/components/personal-advising/personal-advising'
import '@/components/radio/radio'
import '@/components/radio-group/radio-group'
import '@/components/select/select'
import '@/components/select-option/select-option'
import '@/components/tab-menu/tab-menu'
import '@/components/tab-menu-item/tab-menu-item'
import '@/components/teaser/teaser'
import '@/components/teaser-container/teaser-container'
import '@/components/telephone-number-input/telephone-number-input'
import '@/components/textarea/textarea'
import '@/components/table/table'
import '@/components/annotation/annotation'
import '@/components/toggle/toggle'
import '@/components/address-input/address-input'
import '@/components/bmi-calculator/bmi-calculator'
import '@/components/office-search/office-search'
import '@/components/ovulation-calculator/ovulation-calculator'
import '@/components/physician-search/physician-search'
import '@/components/helpful-survey/helpful-survey'
import '@/components/datepicker/datepicker'
import '@/components/datepicker/datepicker-timeframe'
import '@/components/datepicker/datepicker-calendar'
import '@/components/datepicker/datepicker-navigation'
import '@/components/search-filter-nav/search-filter-nav'
import '@/components/search-result/search-result'
import '@/components/video/video'
import '@/components/voluntarily-insured-calculator/voluntarily-insured-calculator'
import '@/components/pkv-survey/pkv-survey'
import '@/components/autocomplete-input-icd/autocomplete-input-icd'
import '@/components/icd-search/icd-search'
import '@/components/citation-teaser/citation-teaser'
import '@/components/floating-button/floating-button'
import '@/components/teaser-link/teaser-link'
import '@/components/tag/tag'
import '@/components/tag-list/tag-list'

// init cookie layer
import '@/components/cookie-layer/cookie-layer.scss'
import CookieLayer from '@/components/cookie-layer/cookie-layer'

CookieLayer.initialize()
