import {LitElement, html} from 'lit';
import {customElement, property, query, queryAssignedElements, state} from 'lit/decorators.js';
import { teaserContainerStyles } from './teaser-container.styles';
import {SbkTeaser} from "@/components/teaser/teaser.ts";
import {map} from "lit/directives/map.js";
import {register, SwiperContainer} from "swiper/element/bundle";
import {contentSliderStyles} from "@/components/content-slider/content-slider.styles.ts";
import {sbkCoreBreakpointLg, sbkCoreBreakpointMd, sbkCoreSpacingXsBase} from "@/tokens/variables";

@customElement('sbk-teaser-container')
export class SbkTeaserContainer extends LitElement {

    @property({attribute: 'load-more-button-text'})
    loadMoreButtonText = '';

    @property({type: Boolean, attribute: 'show-load-more-button'})
    showLoadMoreButton = false;

    @property({type: Number, attribute: 'teaser-per-row-mobile'})
    teaserPerRowMobile = 1;

    @property({type: Number, attribute: 'teaser-per-row-tablet'})
    teaserPerRowTablet = 1;

    @property({type: Number, attribute: 'teaser-per-row-desktop'})
    teaserPerRowDesktop = 2;

    @property({attribute: 'variant-mobile'})
    variantMobile: 'slider' | 'grid' = 'grid';

    @property({attribute: 'variant-tablet'})
    variantTablet: 'slider' | 'grid' = 'grid';

    @property({attribute: 'variant-desktop'})
    variantDesktop: 'slider' | 'grid' = 'grid';

    @queryAssignedElements({selector: 'sbk-teaser'})
    _teaserElements!: Array<SbkTeaser>;

    @state()
    _teaserElementsSlider!: Array<SbkTeaser>;

    @query('swiper-container')
    _swiperContainer!: SwiperContainer;

    _clickCount: number = 1;

    @state()
    _allTeaserVisible: boolean = false;

    static get styles() {
        return teaserContainerStyles;
    }

    constructor() {
        super();
        const ro = new ResizeObserver(() => {
            this.requestUpdate()
        });
        ro.observe(this);
    }

    render() {
        const viewWidth = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0)
        if (((viewWidth < parseInt(sbkCoreBreakpointMd.replace("px","")) && this.variantMobile == 'slider')) ||
            ((viewWidth >= parseInt(sbkCoreBreakpointMd.replace("px","")) && viewWidth < parseInt(sbkCoreBreakpointLg.replace("px","")) && this.variantTablet == 'slider')) ||
            ((viewWidth >= parseInt(sbkCoreBreakpointLg.replace("px","")) && this.variantDesktop == 'slider'))
        ) {
            return html`
                <swiper-container init="false">
                    ${map(this._teaserElementsSlider, (teaserElement) => html`
                        <swiper-slide>
                            ${teaserElement}
                        </swiper-slide>
                            `)}
                </swiper-container>
            `;
        }
        return html`
            <div>
                <slot @slotchange=${this._onSlotChange} class="grid grid-xs-${this.teaserPerRowMobile} grid-sm-${this.teaserPerRowTablet} grid-md-${this.teaserPerRowDesktop}"></slot>
                ${this._renderLoadMoreButton()}
            </div>
        `;
    }

    firstUpdated() {
        this._teaserElementsSlider = Array.from(this.querySelectorAll('sbk-teaser'));
    }

    updated() {
        if (!this._swiperContainer || !this._teaserElementsSlider.length) {
            return;
        }

        if (this._swiperContainer.swiper) {
            return;
        }

        register();
        const params = {
            a11y: true,
            navigation: true,
            watchOverflow: true,
            pagination: {
                type: 'custom',
                renderCustom: function (_swiper: SwiperContainer, current: string, total: string) {
                    if (Number(current) < 10) {
                        current = '0' + current;
                    }
                    if (Number(total) < 10) {
                        total = '0' + total;
                    }
                    return '<span><b>' + current + '</b></span><span>' + '/' + '</span><span>' + total + '</span>';
                }
            },
            injectStyles: [
                contentSliderStyles,
            ],
            slidesPerView: (this.teaserPerRowMobile ?? parseInt('1')) + 0.3,
            spaceBetween: sbkCoreSpacingXsBase,
            breakpoints: {
                768: {
                    slidesPerView: (this.teaserPerRowTablet ?? parseInt('1')) + 0.3,
                },
                992: {
                    slidesPerView: this.teaserPerRowDesktop,
                },
            },

        };
        Object.assign(this._swiperContainer, params);
        this._swiperContainer.initialize();

    }

    _renderLoadMoreButton() {
        if (!this.showLoadMoreButton || this._allTeaserVisible) {
            return '';
        }
        return html`
            <div class="load-more-container">
                <sbk-button @click=${this._showMoreTeaser} variant="secondary" size="small">${this.loadMoreButtonText}</sbk-button>
            </div>
        `;
    }

    _onSlotChange() {
        if (this.showLoadMoreButton) {
            this._teaserElements.forEach((teaserElement, index) => {
                if (index >= this.teaserPerRowDesktop) {
                    teaserElement.hidden = true;
                }
            })
            this._allTeaserVisible = this._teaserElements.length <= this.teaserPerRowDesktop * this._clickCount;
        }
    }

    _showMoreTeaser() {
        this._clickCount++;
        this._teaserElements.forEach((teaserElement, index) => {
            if (index < this.teaserPerRowDesktop * this._clickCount) {
                teaserElement.hidden = false;
            }
        })
        this._allTeaserVisible = this._teaserElements.length <= this.teaserPerRowDesktop * this._clickCount;
    }
}
