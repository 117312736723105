import { customElement, property } from 'lit/decorators.js';
import { html, LitElement, TemplateResult } from 'lit';
import { datepickerStyles } from '@/components/datepicker/datepicker.styles';
import { date, weekday, languageObject } from './datepicker.language';

@customElement('datepicker-calendar')
export class DatepickerCalendar extends LitElement {
    prevMonthDays: { day: number, disabled: boolean }[] = [];
    currentMonthDays: { day: number, disabled: boolean }[] = [];
    nextMonthDays: { day: number, disabled: boolean }[] = [];

    @property({ type: Object }) selectedTimeframe: { month: number, year: number } = {month: new Date().getMonth()+1, year: new Date().getFullYear()};
    @property({ type: Number }) selectedDay: number | null = null;
    @property({ type: Number }) selectedMonth: number | null = null;
    @property({ type: Number }) selectedYear: number | null = null;
    @property({ type: Boolean }) allowSelectingDaysInPrevAndNextMonths: boolean = true;
    @property({ type: String }) minDate: string = '1900-01-01';
    @property({ type: String }) maxDate: string = '2035-12-31';
    @property({ type: Boolean }) disableWeekendDays: boolean = false;
    @property({ type: String }) disabledDays: string = '';

    constructor() {
        super();
    }

    static styles = [
        datepickerStyles
    ];

    setLanguage(element: languageObject) {
        const lang = document.documentElement.lang;
        if (lang.includes('de')) {
          return element.German;
        } else {
          return element.English;
        }
    }

    render() {
        return html`
            <table role="grid">
              <thead>
                <tr>
                  ${this.setLanguage(weekday).map(day => html`<th scope="col">${day}</th>`)}
                </tr>
              </thead>
              <tbody>
                ${this.getCalendarRows()}
              </tbody>
            </table>
        `;
    }

    generateCalendar(month: number = this.selectedTimeframe['month'], year: number = this.selectedTimeframe['year']) {
        const firstDayOfMonth = new Date(year, month - 1, 1).getDay() || 7;
        const daysInPrevMonth = new Date(year, month - 1, 0).getDate();
        const daysInCurrentMonth = new Date(year, month, 0).getDate();
        const prevMonthDays = Array.from({ length: firstDayOfMonth - 1 }, (_, i) => ({
          day: daysInPrevMonth - i,
          disabled: !this.allowSelectingDaysInPrevAndNextMonths
        })).reverse();

        const currentMonthDays = Array.from({ length: daysInCurrentMonth }, (_, i) => ({
          day: i + 1,
          disabled: false
        }));

        const nextMonthDays = Array.from({ length: (42 - (prevMonthDays.length + currentMonthDays.length)) }, (_, i) => ({
          day: i + 1,
          disabled: !this.allowSelectingDaysInPrevAndNextMonths
        }));

        this.prevMonthDays = prevMonthDays;
        this.currentMonthDays = currentMonthDays;
        this.nextMonthDays = nextMonthDays;
      }

    getCalendarRows() {
        this.generateCalendar();

        const rows: TemplateResult[] = [];
        let cells: TemplateResult[] = [];

        this.prevMonthDays.forEach(({ day, disabled }, index) => {
          if (cells.length >= 7) {
            rows.push(html`<tr>${cells}</tr>`);
            cells = [];
          }

          const cellId = `${this.selectedTimeframe['year']}-${this.selectedTimeframe['month']}-${day}-${index}`;

          cells.push(html`
            <td data-id=${cellId}>
              <sbk-round-button
                  size="medium"
                  class="datepicker__calendar-item datepicker__calendar-item--not-current ${this.handleClass(day, this.selectedTimeframe['month'] - 1, this.selectedTimeframe['year'])}"
                  @click=${() => this.setDay(day, this.selectedTimeframe['month']-1)}
                  @keydown=${(event: KeyboardEvent) => this.handleDayKeyDown(event, cellId)}
                  ?disabled=${this.isDayDisabled(day, this.selectedTimeframe['month']-1) || disabled}
                  tabindex="0"
                  aria-label="${this.setLanguage(date.day)} ${day}"
                  variant="text"
              >
                ${day}
              </sbk-round-button>
            </td>
          `);
        });

        this.currentMonthDays.forEach(({ day, disabled }, index) => {
          if (cells.length >= 7) {
            rows.push(html`<tr>${cells}</tr>`);
            cells = [];
          }

          const cellId = `${this.selectedTimeframe['year']}-${this.selectedTimeframe['month']}-${day}-${index}`;

          cells.push(html`
            <td data-id=${cellId}>
              <sbk-round-button
                size="medium"
                class="datepicker__calendar-item ${this.handleClass(day, this.selectedTimeframe['month'], this.selectedTimeframe['year'])}"
                @click=${() => this.setDay(day)}
                @keydown=${(event: KeyboardEvent) => this.handleDayKeyDown(event, cellId)}
                ?disabled=${this.isDayDisabled(day) || disabled}
                tabindex="0"
                aria-label="${this.setLanguage(date.day)} ${day}"
                variant="text"
              >
                ${day}
              </sbk-round-button>
            </td>
          `);
        });

        this.nextMonthDays.forEach(({ day, disabled }, index) => {
          if (cells.length >= 7) {
            rows.push(html`<tr>${cells}</tr>`);
            cells = [];
          }

          const cellId = `${this.selectedTimeframe['year']}-${this.selectedTimeframe['month']}-${day}-${index}`;

          cells.push(html`
            <td data-id=${cellId}>
              <sbk-round-button
                size="medium"
                class="datepicker__calendar-item datepicker__calendar-item--not-current ${this.handleClass(day, this.selectedTimeframe['month'] + 1, this.selectedTimeframe['year'])}"
                @click=${() => this.setDay(day, this.selectedTimeframe['month']+1)}
                @keydown=${(event: KeyboardEvent) => this.handleDayKeyDown(event, cellId)}
                ?disabled=${this.isDayDisabled(day, this.selectedTimeframe['month']+1) || disabled}
                tabindex="0"
                aria-label="${this.setLanguage(date.day)} ${day}"
                variant="text"
              >
                ${day}
              </sbk-round-button>
            </td>
          `);
        });

        if (cells.length) {
          rows.push(html`<tr>${cells}</tr>`);
        }

        return rows;
    }

    handleClass(day: number, month: number, year: number): string {
        if (this.isSelectedDay(day, month, year)) {
          return 'selected';
        } else if (this.isToday(day, month, year)) {
          return 'today';
        }
        return '';
      }

    isSelectedDay(day: number, month: number, year: number): boolean {
        return this.selectedDay === day && this.selectedMonth === month && this.selectedYear === year;
    }

    isToday(day: number, month: number, year: number): boolean {
        const today = new Date();
        return day === today.getDate() && month === today.getMonth() + 1 && year === today.getFullYear();
    }

    isDayDisabled(day: number, month: number = this.selectedTimeframe['month'], year: number = this.selectedTimeframe['year']): boolean {
        const date = new Date(year, month - 1, day);
        const minDate = new Date(this.minDate);
        const min = new Date(minDate.getFullYear(), minDate.getMonth(), minDate.getDate());
        const max = new Date(this.maxDate);
        const isWeekendDisabled = this.disableWeekendDays && (date.getDay() === 0 || date.getDay() === 6); // Sunday = 0 and Saturday = 6
        let isInDisabledDays = false;
        const disabledTimestampArray  = this.disabledDays.split('|');
        for (const timestamp of disabledTimestampArray) {
          const disabledDay = new Date(parseInt(timestamp) * 1000); // convert from seconds to milliseconds
          if (`${day}.${month}` === `${disabledDay.getDate()}.${disabledDay.getMonth()+1}`) {
            isInDisabledDays = true;
            break;
          }
        }
        return date < min || date > max || isWeekendDisabled || isInDisabledDays;
    }

    setDay(day: number, month: number = this.selectedTimeframe['month'], year: number = this.selectedTimeframe['year']) {
        if (!this.isDayDisabled(day, month)) {
          this.selectedDay = day;
          this.selectedMonth = month;
          this.selectedYear = year;
          if (month !== this.selectedTimeframe['month']) {
            this.selectedTimeframe['month'] = month;
          }
          if (year !== this.selectedTimeframe['year']) {
            this.selectedTimeframe['year'] = year;
          }
          this.dispatchEvent(new CustomEvent('date-selected', {
            detail: { day: day, month: month, year: year }
          }));
        }
      }

    handleDayKeyDown(event: KeyboardEvent, cellId: string) {
        const directions: { [key: string]: number } = {
          'ArrowUp': -7,
          'ArrowDown': 7,
          'ArrowLeft': -1,
          'ArrowRight': 1
        };

        if (event.key in directions) {
          event.preventDefault();
          const allDays = Array.from(this.shadowRoot!.querySelectorAll('td'));
          const currentIndex = allDays.findIndex(td => td.getAttribute('data-id') === cellId);
          const newIndex = currentIndex + directions[event.key];
          if (newIndex >= 0 && newIndex < allDays.length) {
            const newCell = allDays[newIndex];
            const roundButton = newCell.querySelector('sbk-round-button') as HTMLElement;
            roundButton?.focus();
          }
        } else if (event.key === 'Tab') {
          this.handleTabKey(event);
        }
      }

    handleTabKey(event: KeyboardEvent) {
        event.preventDefault();
        this.dispatchEvent(new CustomEvent('tab-keydown', {
            detail: event
        }));
    }

    focus() {
        const firstDay = Array.from(this.shadowRoot!.querySelectorAll('td'))[0];
        const roundButton = firstDay.querySelector('sbk-round-button') as HTMLElement;
        roundButton?.focus();
    }
}
