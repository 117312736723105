import {css, unsafeCSS} from "lit";
import {sbkCoreBreakpointLg, sbkCoreBreakpointMd, sbkCoreBreakpointXl} from "@/tokens/variables";

const breakpointMd = unsafeCSS(`${sbkCoreBreakpointMd}`);
const breakpointLg = unsafeCSS(`${sbkCoreBreakpointLg}`);
const breakpointXl = unsafeCSS(`${sbkCoreBreakpointXl}`);

export const floatingButtonStyles = css`

    :host {
        --content-width: calc(var(--sbkCoreContentAreaMaxWidth) + 2* var(--sbkCoreGridXlMargin));
    }

    sbk-button {
        display: none;
        position: fixed;
        right: var(--sbkCoreGridSmMargin);
        bottom: var(--sbkButtonFloatingMarginBottom);
        z-index: 9;

        @media all and (min-width: ${breakpointMd}) {
            right: var(--sbkCoreGridMdMargin);
        }

        @media all and (min-width: ${breakpointLg}) {
            right: var(--sbkCoreGridLgMargin);
        }

        @media all and (min-width: ${breakpointXl}) {
            display: block;
            right: calc((100vw - var(--content-width)) / 2 + 72px);
        }
    }

    sbk-button::part(button) {
        border: 0;
        background-color: var(--sbkButtonBgColorFloatingOnLightEnabled);
        box-shadow: var(--sbkButtonShadowFloating);
    }

    sbk-button::part(button):hover {
        background-color: var(--sbkButtonBgColorFloatingOnLightHover);
    }

    sbk-button::part(button):active {
        background-color: var(--sbkButtonBgColorFloatingOnLightActive);
        color: var(--sbkButtonFgColorOnDarkDefault);
    }

    sbk-button::part(button):focus {
        background-color: var(--sbkButtonBgColorFloatingOnLightFocus);
        color: var(--sbkButtonFgColorOnDarkDefault);
    }



    sbk-modal::part(dialog) {
        max-width: calc(100vw - var(--sbkCoreGridSmMargin) * 2);
        margin-right: var(--sbkCoreGridSmMargin);
        margin-bottom: var(--sbkButtonFloatingMarginBottom);
        margin-top: auto;
        padding: 0;
        max-height: 50vh;
        width: 100%;
    }

    @media all and (min-width: ${breakpointMd}) {
        sbk-modal::part(dialog) {
            max-width: var(--sbkModalSizingMaxWidthMdViewport);
            margin-right: var(--sbkCoreGridMdMargin);
        }
    }

    @media all and (min-width: ${breakpointLg}) {
        sbk-modal::part(dialog) {
            max-width: var(--sbkModalSizingMaxWidthLgViewport);
            margin-right: var(--sbkCoreGridLgMargin);
        }
    }

    @media all and (min-width: ${breakpointXl}) {
        sbk-modal::part(dialog) {
            max-width: var(--sbkModalSizingMaxWidthLgViewport);
            margin-right: calc((100vw - var(--content-width)) / 2 + var(--sbkCoreGridLgMargin));
        }
    }
`;