import { customElement, property } from 'lit/decorators.js';
import { html, LitElement } from 'lit';
import {query} from 'lit/decorators/query.js';
import { datepickerStyles } from '@/components/datepicker/datepicker.styles';
import { datepickerNavigation, languageObject } from './datepicker.language';

@customElement('datepicker-navigation')
export class DatepickerNavigation extends LitElement {
    @property({ type: Boolean }) prevButtonDisabled: boolean = false;
    @property({ type: Boolean }) nextButtonDisabled: boolean = false;

    @query('.prev') _prevButton!: HTMLButtonElement;
    @query('.next') _nextButton!: HTMLButtonElement;

    month: string[] = [];

    constructor() {
        super();
    }

    static styles = [
        datepickerStyles
    ];

    render() {
        return html`
          <div class="datepicker-navigation">
              <sbk-round-button class="prev navigation-button"
                    @mousedown=${() => this.changeMonth(-1)}
                    @touchdown=${() => this.changeMonth(-1)}
                    @keydown=${this.handleTabKey}
                    ?disabled=${this.prevButtonDisabled}
                    tabindex="0" icon="chevron-left" variant="icon-only" size="medium">${this.setLanguage(datepickerNavigation.previous)}</sbk-round-button>
              <sbk-round-button class="next navigation-button"
                    @mousedown=${() => this.changeMonth(1)}
                    @touchdown=${() => this.changeMonth(1)}
                    @keydown=${this.handleTabKey}
                    ?disabled=${this.nextButtonDisabled}
                    tabindex="0" icon="chevron-right" variant="icon-only" size="medium">${this.setLanguage(datepickerNavigation.next)}</sbk-round-button>
            </div>
        `;
    }

    updated() {
        if (!this._prevButton.disabled) {
          this._prevButton.removeAttribute('disabled');
        }
        if (!this._nextButton.disabled) {
          this._nextButton.removeAttribute('disabled');
        }
    }

    setLanguage(element: languageObject) {
        const lang = document.documentElement.lang;
        if (lang.includes('de')) {
          return element.German;
        } else {
          return element.English;
        }
    }

    changeMonth(increment: number) {
        this.dispatchEvent(new CustomEvent('navigation-changed', {
          detail: { increment: increment }
        }));
    }

    handleTabKey(event: KeyboardEvent) {
        if (event.target === this._prevButton) {
            event.preventDefault();
            if (!event.shiftKey) {
                this._nextButton.focus();
            } else {
                this.dispatchEvent(new CustomEvent('navigation-tab-keydown', {
                  detail: 'prev'
                }));
            }
        } else if (event.target === this._nextButton) {
            event.preventDefault();
            if (event.shiftKey) {
                this._prevButton.focus();
            } else {
                this.dispatchEvent(new CustomEvent('navigation-tab-keydown', {
                  detail: 'next'
                }));
            }
        }
    }

    focus() {
        this._prevButton.focus();
    }
}
