import { customElement, property } from 'lit/decorators.js';
import { html, LitElement } from 'lit';
import {query} from 'lit/decorators/query.js';
import { datepickerStyles } from '@/components/datepicker/datepicker.styles';
import { date, month, languageObject } from './datepicker.language';

@customElement('datepicker-timeframe')
export class DatepickerTimeframe extends LitElement {
    @property({ type: String }) minDate: string = '1900-01-01';
    @property({ type: String }) maxDate: string = '2035-12-31';
    @property({ type: Number }) selectedMonth!: number;
    @property({ type: Number }) selectedYear!: number;

    @query('.month-select') _monthSelect!: HTMLSelectElement;
    @query('.year-select') _yearSelect!: HTMLSelectElement;

    month: string[] = [];

    constructor() {
        super();
    }

    static styles = [
        datepickerStyles
    ];

    render() {
        return html`
          <div class="select-wrapper">
              <div class="select-container select-container--month">
                <select name="month" class="month-select" @change=${this.handleMonthChange} tabindex="0" aria-label=${this.setLanguage(date.month)}>
                  ${this.getMonthOptions()}
                </select>
                <sbk-icon class="select-icon" size="24" symbol="chevron-down"></sbk-icon>
              </div>
              <div class="select-container select-container--year">
                <select name="year" class="year-select" @change=${this.handleYearChange} @keydown=${this.handleTabKey} tabindex="0" aria-label=${this.setLanguage(date.year)}>
                  ${this.getYearOptions()}
                </select>
                <sbk-icon class="select-icon" size="24" symbol="chevron-down"></sbk-icon>
              </div>
            </div>
        `;
    }

    setLanguage(element: languageObject) {
        const lang = document.documentElement.lang;
        if (lang.includes('de')) {
          return element.German;
        } else {
          return element.English;
        }
      }

    getMonthOptions() {
        const months = this.setLanguage(month);
        return months.map((month, index) => html`
          <option ?selected=${this.selectedMonth === index + 1} ?disabled=${this.isMonthDisabled(index + 1)}>${month}</option>
        `);
    }

    getYearOptions() {
        const minYear = new Date(this.minDate).getFullYear();
        const maxYear = new Date(this.maxDate).getFullYear();

        return Array.from({ length: maxYear - minYear + 1 }, (_, i) => minYear + i).map(year => html`
          <option ?selected=${this.selectedYear === year}>${year}</option>
        `);
    }

    handleMonthChange() {
        for (const option of this._monthSelect.options) {
          if (option.selected) {
            this.selectedMonth = this.setLanguage(month).indexOf(option.text) + 1;
          }
        }
        this.dispatchEvent(new CustomEvent('select-changed', {
          detail: { month: this.selectedMonth, year: this.selectedYear }
        }));
    }

    handleYearChange() {
        for (const option of this._yearSelect.options) {
          if (option.selected) {
            this.selectedYear = parseInt(option.text, 10);
            break;
          }
        }
        this.dispatchEvent(new CustomEvent('select-changed', {
          detail: { month: this.selectedMonth, year: this.selectedYear }
        }));
    }

    isMonthDisabled(month: number, year: number = this.selectedYear): boolean {
        const firstDayOfMonth = new Date(year, month - 1, 1);
        const lastDayOfMonth = new Date(year, month, 0);

        const minDate = new Date(this.minDate);
        const min = new Date(minDate.getFullYear(), minDate.getMonth(), minDate.getDate());
        const max = new Date(this.maxDate);

        const isOverlapped = (firstDayOfMonth >= min && firstDayOfMonth <= max) || (lastDayOfMonth >= min && lastDayOfMonth <= max);

        return !isOverlapped;
    }

    updateMonthSelect() {
        for (const option of this._monthSelect.options) {
          option.selected = false;
        }
        this._monthSelect.options[this.selectedMonth - 1].selected = true;
    }

    updateYearSelect() {
        for (const option of this._yearSelect.options) {
          option.selected = false;
        }
        for (const option of this._yearSelect.options) {
          if (parseInt(option.text, 10) === this.selectedYear) {
            option.selected = true;
            break;
          }
        }
    }

    handleTabKey(event: KeyboardEvent) {
        if (event.key === 'Tab' && !event.shiftKey) {
            event.preventDefault();
            this.dispatchEvent(new CustomEvent('tab-keydown'));
        }
    }

    focus() {
        this._yearSelect.focus();
    }
}
