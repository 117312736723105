import {customElement, property, state} from 'lit/decorators.js';
import {LitElement, html, PropertyValues} from 'lit';
import {Loader} from "@googlemaps/js-api-loader";
import { MarkerClusterer } from "@googlemaps/markerclusterer";
import {query} from "lit/decorators/query.js";
import {
    sbkColorContentBgColorEmphasised,
    sbkColorContentBgColorMuted,
} from "@/tokens/variables";
import CookieLayer from "@/components/cookie-layer/cookie-layer";
import '@/components/cookie-overlay/cookie-overlay'
import {Cluster} from "@googlemaps/markerclusterer/dist/cluster";
import {Renderer} from "@googlemaps/markerclusterer/dist/renderer";
import {mapStyles} from "@/components/map/map.styles.ts";

export interface MarkerData {
  content: string,
  lat: string,
  lng: string,
}

@customElement('sbk-map')
export class SbkMap extends LitElement{

  @query('#map')
  _map!: HTMLDivElement;

    @property({attribute: 'map-id'})
    mapId: string = '';

    @property()
    markers: string = '';

    @property({type: Number})
    zoom: number = 15;

    @state()
    cookiesAccepted: boolean;

    constructor() {
        super();
        this.cookiesAccepted = CookieLayer.isCookieConsentLoaded();
    }

    connectedCallback() {
        super.connectedCallback();
        document.addEventListener('third-party-cookies-accepted', this._onCookiesAccepted);
    }
    disconnectedCallback() {
        document.removeEventListener('third-party-cookies-accepted', this._onCookiesAccepted);
        super.disconnectedCallback();
    }

    render() {
        if (!this.cookiesAccepted) {
            return html`
            <sbk-cookie-overlay></sbk-cookie-overlay>
        `;
        }
        return html`
            <div id="map" @load=${this.initializeMap} class="googleMaps cookie-consent-iframe" style="width: 100%;height: 400px"></div>
        `;
    }

    static get styles() {
        return [mapStyles];
    }

    updated(changedProperties: PropertyValues<this>) {
        if (changedProperties.has('cookiesAccepted')) {
            if (this._map) {
                this.initializeMap()
            }
        }
    }

  initializeMap() {
      const loader = new Loader({
          apiKey: "AIzaSyBw4heb8V5yMdNPkyp2UdB553qx0CsmOcI",
          version: "weekly"
      });

      loader.load().then(async () => {
          // Request needed libraries.

          const {Map, InfoWindow} = await google.maps.importLibrary("maps") as google.maps.MapsLibrary;
          const {
              AdvancedMarkerElement,
              PinElement
          } = await google.maps.importLibrary("marker") as google.maps.MarkerLibrary;

          const mapID = this.mapId ? this.mapId : "SBK_MAP_ID";

          const map = new Map(this._map, {
              zoom: 11,
              mapTypeId: google.maps.MapTypeId.ROADMAP,
              mapId: mapID,
          });
          const infoWindow = new InfoWindow({
              content: "",
              maxWidth: 300,
          });
          const markerBounds = new google.maps.LatLngBounds();

          const markerData: MarkerData[] = JSON.parse(this.markers);

          // Add some markers to the map.
          const markers = markerData.map((data) => {
              const position = new google.maps.LatLng({lat: parseFloat(data.lat), lng: parseFloat(data.lng)})
              const pinBackground = new PinElement({
                  background: sbkColorContentBgColorEmphasised,
                  glyphColor: sbkColorContentBgColorMuted,
                  borderColor: sbkColorContentBgColorMuted,
              });
              const marker = new AdvancedMarkerElement({
                  position: position,
                  content: pinBackground.element,
              });

              // markers can only be keyboard focusable when they have click listeners
              // open info window when marker is clicked
              marker.addListener('click', () => {
                  infoWindow.setContent(data.content);
                  infoWindow.open(map, marker);
              });
              markerBounds.extend(position)
              return marker;
          });

      // Add a marker clusterer to manage the markers.
      new MarkerClusterer({ markers, map, renderer: new SbkClusterRenderer });

      map.setCenter(markerBounds.getCenter())
      map.setZoom(this.zoom)
    });
  }

    _onCookiesAccepted = () => {
        this.cookiesAccepted = true;
    }

}

export class SbkClusterRenderer implements Renderer {
    public render({ count, position }: Cluster) {
        // change color if this cluster has more markers than the mean cluster
        const color = sbkColorContentBgColorEmphasised;

        // create svg url with fill color
                const svg = window.btoa(`
        <svg fill="${color}" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 240 240">
          <circle cx="120" cy="120" opacity="1" r="70" />
          <circle cx="120" cy="120" opacity=".5" r="90" />
          <circle cx="120" cy="120" opacity=".3" r="110" />
          <circle cx="120" cy="120" opacity=".1" r="130" />
        </svg>`);

        // create marker using svg icon
        return new google.maps.Marker({
            position,
            icon: {
                url: `data:image/svg+xml;base64,${svg}`,
                scaledSize: new google.maps.Size(45, 45),
            },
            label: {
                text: String(count),
                color: "rgba(255,255,255,0.9)",
                fontSize: "12px",
            },
            // adjust zIndex to be above other markers
            zIndex: 1000 + count,
        });
    }
}
