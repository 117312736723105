import { customElement, property, queryAssignedElements } from 'lit/decorators.js';
import {html, LitElement} from "lit";
import {telephoneNumberInputStyles} from "@/components/telephone-number-input/telephone-number-input.styles";
import {SbkInput} from "@/components/input/input";
import {SbkSelect} from "@/components/select/select";
import {FormValidationMixin} from "@/components/mixins/form-validation-mixin";
import {SbkLabelMixin} from "@/components/label/label-mixin";
import { when } from 'lit/directives/when.js';
import {labelStyles} from "@/components/label/label.styles";
import {FormControlDependencyMixin} from "@/components/form-control-dependency/form-control-dependency-mixin";

/**
 * An example element.
 *
 * @slot - This element has a slot
 */
@customElement('sbk-telephone-number-input')
export class SbkTelephoneNumberInput extends FormControlDependencyMixin(FormValidationMixin(SbkLabelMixin(LitElement))) {

    static shadowRootOptions = {...LitElement.shadowRootOptions, delegatesFocus: true};

    @queryAssignedElements({slot: 'countryCodeSelect'})
    _telephoneNumberCountryCodeSelect!: Array<SbkSelect>;

    @queryAssignedElements({slot: 'nationalNumberInput'})
    _telephoneNumberNationalNumberInput!: Array<SbkInput>;

    @property({type: Boolean})
    disabled: boolean = false

    @property()
    description: string = ''

    constructor() {
        super();
    }

    static get styles() {
        return [
            labelStyles,
            telephoneNumberInputStyles
        ];
    }

    render() {
        return html`
            <fieldset class="input__wrapper${this.invalid ? ' input__wrapper--error' : ''}${this.disabled ? ' input__wrapper--disabled' : ''}" aria-describedby="annotation" aria-labelledby="groupLabel">
                ${this.labelTemplate("groupLabel", true)}
                ${when(this.description && !this.invalid,
                        () => html`
                            <sbk-annotation id="annotation" variant="info" ?disabled=${this.disabled}>${this.description}</sbk-annotation>
                        `)}
                ${this.invalid ? this.validationErrorTemplate("annotation") : ""}
                <slot name="typeSelect"></slot>
                <div class="row">
                    <div class="col-md-4">
                        <slot @select-value-changed=${this._onCountryCodeChange} name="countryCodeSelect"></slot>
                    </div>
                    <div class="col-md-8">
                        <slot @input-change=${this._onNationalInputChange} name="nationalNumberInput"></slot>
                    </div>
            </fieldset>
        `;
    }

    _onCountryCodeChange() {
        this.invalid = false;
    }

    _onNationalInputChange() {
        if (this._telephoneNumberCountryCodeSelect[0].selectedOption.value === '+49') {
            this._telephoneNumberNationalNumberInput[0].value = this._telephoneNumberNationalNumberInput[0].value.replace(/^0/, '')
        }
        this.invalid = false;
    }
}
