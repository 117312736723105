import {customElement, queryAssignedElements} from 'lit/decorators.js';
import {html, LitElement} from "lit";
import {FormControlDependencyMixin} from "@/components/form-control-dependency/form-control-dependency-mixin";

/**
 * An example element.
 *
 * @slot - This element has a slot
 */
@customElement('sbk-form-control-dependency')
export class SbkFormControlDependency extends FormControlDependencyMixin(LitElement) {

    @queryAssignedElements()
    _slottedElements!: Array<HTMLElement>;

    constructor() {
        super();
        this.addEventListener('form-control-dependency-show', () => {
            this.show();
            this.setValue();
        });
        this.addEventListener('form-control-dependency-hide', () => {
            this.hide();
            this.resetValue();
        });
    }

    render() {
        return html`
            <slot></slot>
        `;
    }

    hide(): void {
        this.hidden = true;
    }

    show(): void {
        this.hidden = false;
    }

    resetValue(): void {
        return
    }

    setValue(): void {
        return
    }
}
