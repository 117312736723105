import { css, unsafeCSS } from 'lit';
import {sbkCoreBreakpointLg, sbkCoreBreakpointMd, sbkCoreBreakpointXl} from "@/tokens/variables";

const breakpointMd = unsafeCSS(`${sbkCoreBreakpointMd}`);
const breakpointLg = unsafeCSS(`${sbkCoreBreakpointLg}`);
const breakpointXl = unsafeCSS(`${sbkCoreBreakpointXl}`);

export const teaserContainerStyles = css` 
    :host {
        display: block;
    }
    
    .grid {
        display: grid;
        grid-gap: var(--sbkCoreGridSmGutter);
        grid-auto-rows: 1fr;
        padding-bottom: var(--sbkCoreSpacingXsBase);

        @media (min-width: ${breakpointMd}) {
            grid-gap: var(--sbkCoreGridMdGutter);
        }
        @media (min-width: ${breakpointLg}) {
            grid-gap: var(--sbkCoreGridLgGutter);
        }
        @media (min-width: ${breakpointXl}) {
            grid-gap: var(--sbkCoreGridXlGutter);
        }
    }

    @media (max-width: ${breakpointMd}) {
        .grid-xs-1 {
            grid-template-columns: minmax(0, 1fr);
        }
    }

    @media (min-width: ${breakpointMd}) {
        .grid-sm-1 {
            grid-template-columns: minmax(0, 1fr);
        }
        .grid-sm-2 {
            grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
        }
    }

    @media (min-width: ${breakpointXl}) {
        .grid-md-1 {
            grid-template-columns: minmax(0, 1fr);
        }
        .grid-md-2 {
            grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
        }
        .grid-md-3 {
            grid-template-columns: minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr);
        }
        .grid-md-4 {
            grid-template-columns: minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr);
        }
    }

    .load-more-container {
        margin: var(--sbkCoreSpacingMd) 0;
        text-align: center;
    }
    
    ::slotted(*) {
        height: 100%;
    }
    
`;
