import {html, LitElement} from 'lit';
import {customElement, property} from 'lit/decorators.js';
import {teaserLinkStyles} from "@/components/teaser-link/teaser-link.styles";


@customElement('sbk-teaser-link')
export class SbkTeaserLink extends LitElement {

    @property()
    icon = '';

    @property()
    headline = '';

    @property()
    href = '';

    static get styles() {
        return teaserLinkStyles;
    }

    render() {
        return html`
            <sbk-icon size='32' symbol=${this.icon}></sbk-icon>
            <div class="teaser-link__content">
                ${this.href ? this.renderHeadlineLink() : this.renderHeadline()}
                <span class="teaser-link__body"><slot></slot></span>
            </div>
        `;
    }

    renderHeadlineLink() {
        return html`
            <sbk-headline-link href=${this.href}>${this.headline}</sbk-headline-link>
        `;
    }

    renderHeadline() {
        return html`
            <p class="headline" href=${this.href}>${this.headline}</p>
        `;
    }
}
