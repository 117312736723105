import { LitElement, html } from 'lit';
import { style } from './form-steps.styles';
import { customElement } from 'lit/decorators.js';

@customElement('sbk-form-steps')
export class SbkFormSteps extends LitElement {
  static get styles() {
    return style;
  }

  render() {
    return html`
      <div class="form-steps">
        <slot></slot>
      </div>
    `;
  }
}
