import { customElement, property, queryAssignedElements } from 'lit/decorators.js';
import {html, LitElement, PropertyValues} from "lit";
import {addressInputStyles} from "@/components/address-input/address-input.styles";
import {SbkInput} from "@/components/input/input";
import {SbkSelect} from "@/components/select/select";
import {FormValidationMixin} from "@/components/mixins/form-validation-mixin";
import {SbkLabelMixin} from "@/components/label/label-mixin";
import { when } from 'lit/directives/when.js';
import {labelStyles} from "@/components/label/label.styles";
import {FormControlDependencyMixin} from "@/components/form-control-dependency/form-control-dependency-mixin";
import "@/components/autocomplete-input-city/autocomplete-input-city.ts";
import "@/components/autocomplete-input-zip/autocomplete-input-zip.ts";
import {SbkAutocompleteInputCity} from "@/components/autocomplete-input-city/autocomplete-input-city.ts";
import {SbkAutocompleteInputZip} from "@/components/autocomplete-input-zip/autocomplete-input-zip.ts";
import "@/components/autocomplete-input-street/autocomplete-input-street";
import {SbkAutocompleteInputStreet} from "@/components/autocomplete-input-street/autocomplete-input-street";
import {AutocompleteMixinInterface} from "@/components/autocomplete/autocomplete-mixin.ts";

/**
 * An example element.
 *
 * @slot - This element has a slot
 */
@customElement('sbk-address-input')
export class SbkAddressInput extends FormControlDependencyMixin(FormValidationMixin(SbkLabelMixin(LitElement))) {

    static shadowRootOptions = {...LitElement.shadowRootOptions, delegatesFocus: true};

    @queryAssignedElements({slot: 'countrySelect'})
    _countrySelect!: Array<SbkSelect>;

    @queryAssignedElements({slot: 'zipInput'})
    _zipInput!: Array<SbkAutocompleteInputZip>;

    @queryAssignedElements({slot: 'cityInput'})
    _cityInput!: Array<SbkAutocompleteInputCity>;

    @queryAssignedElements({slot: 'streetInput'})
    _streetInput!: Array<SbkAutocompleteInputStreet>;

    @queryAssignedElements({slot: 'numberInput'})
    _numberInput!: Array<SbkInput>;

    @queryAssignedElements({slot: 'additionInput'})
    _additionInput!: Array<SbkInput>;

    @property({type: Boolean})
    disabled: boolean = false

    @property()
    description: string = ''

    zipInputValue: string = '';
    cityInputValue: string = '';

    willUpdate(changedProperties: PropertyValues<this>) {
        // only need to check changed properties for an expensive computation.
        if (changedProperties.has('invalid')) {
            this._countrySelect[0] && (this._countrySelect[0].invalid = this.invalid);
            this._zipInput[0] && (this._zipInput[0].invalid = this.invalid);
            this._cityInput[0] && (this._cityInput[0].invalid = this.invalid);
            this._streetInput[0] && (this._streetInput[0].invalid = this.invalid);
            this._numberInput[0] && (this._numberInput[0].invalid = this.invalid);
            if (this._additionInput[0]) {
                this._additionInput[0].invalid = this.invalid;
            }
        }
    }

    static get styles() {
        return [
            labelStyles,
            addressInputStyles
        ];
    }

    render() {
        return html`
            <fieldset class="input__wrapper${this.invalid ? ' input__wrapper--error' : ''}${this.disabled ? ' input__wrapper--disabled' : ''}" aria-describedby="annotation" aria-labelledby="groupLabel">
                ${this.labelTemplate("groupLabel", true)}
                ${when(this.description && !this.invalid,
                        () => html`
                            <sbk-annotation id="annotation" variant="info" ?disabled=${this.disabled}>${this.description}</sbk-annotation>
                        `)}
                ${this.invalid ? this.validationErrorTemplate("annotation") : ""}
                <slot @slotchange=${this._onCountrySlotChange} @select-value-changed=${this._onCountrySelectChange} name="countrySelect"></slot>
                <div class="row">
                    <div class="col-md-4">
                        <slot @slotchange=${this._onZipInputSlotChange} @input-value-changed=${this._onZipInputChange} name="zipInput"></slot>
                    </div>
                    <div class="col-md-8">
                        <slot @slotchange=${this._onCityInputSlotChange} @input-value-changed=${this._onCityInputChange} name="cityInput"></slot>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-8">
                        <slot @slotchange=${this._onStreetInputSlotChange} @input-value-changed=${this._onStreetInputChange} name="streetInput"></slot>
                    </div>
                    <div class="col-md-4">
                        <slot @slotchange=${this._onNumberInputSlotChange} name="numberInput"></slot>
                    </div>
                </div>
                <slot @slotchange=${this._onAdditionInputSlotChange} name="additionInput"></slot>
            </fieldset>
        `;
    }

    _onCountrySlotChange() {
        if (this._countrySelect[0].selectedOption.value !== 'DE') {
            this._disableAutocomplete();
        } else {
            this._enableAutocomplete();
        }
        if (this.invalid) {
            this._countrySelect[0].invalid = true;
        }
    }

    _onZipInputSlotChange() {
        this._setInvalid(this._zipInput[0])
    }

    _onCityInputSlotChange() {
        this._setInvalid(this._cityInput[0])
    }

    _onStreetInputSlotChange() {
        this._setInvalid(this._streetInput[0])
    }

    _onNumberInputSlotChange() {
        this._setInvalid(this._numberInput[0])
    }

    _onAdditionInputSlotChange() {
        this._additionInput[0] && this._setInvalid(this._additionInput[0])
    }


    _setInvalid(element: AutocompleteMixinInterface|SbkInput) {
        if (this.invalid) {
            element.invalid = true;
        }
    }

    _onCountrySelectChange(e: CustomEvent) {
        if (e.detail.value !== 'DE') {
            this._disableAutocomplete();
        } else {
            this._enableAutocomplete();
        }
        this.invalid = false;
    }

    _enableAutocomplete() {
        this._zipInput[0].autocompleteDisabled = false;
        this._cityInput[0].autocompleteDisabled = false;
        this._streetInput[0].autocompleteDisabled = false;
    }

    _disableAutocomplete() {
        this._zipInput[0].autocompleteDisabled = true;
        this._cityInput[0].autocompleteDisabled = true;
        this._streetInput[0].autocompleteDisabled = true;
    }

    _onZipInputChange(e: CustomEvent) {
        this.zipInputValue = e.detail.value;
        if (!e.detail.value || !this._cityInput.length) {
            return
        }
        this.invalid = false;
        this._cityInput[0].zipInputValue = this.zipInputValue;
        this._cityInput[0].focus();
    }

    _onCityInputChange(e: CustomEvent) {
        this.cityInputValue = e.detail.value;
        if (!e.detail.value || !this._streetInput.length) {
            return
        }
        this.invalid = false;
        this._streetInput[0].zipInputValue = this.zipInputValue;
        this._streetInput[0].cityInputValue = this.cityInputValue;
        this._streetInput[0].focus();
    }

    _onStreetInputChange(e: CustomEvent) {
        if (!e.detail.value || !this._numberInput.length) {
            return
        }
        this.invalid = false;
        this._numberInput[0].focus();
    }
}
