import {customElement, property} from 'lit/decorators.js';
import {html, LitElement} from 'lit';
import { personalAdvisingStyles } from './personal-advising.styles'
import {unsafeHTML} from "lit/directives/unsafe-html.js";

@customElement('sbk-personal-advising')
export class SbkPersonalAdvising extends LitElement {

  @property()
  heading: string = '';

  @property()
  introduction: string = '';

  @property({ attribute: 'person-name' })
  personName: string = '';

  @property({ attribute: 'job-title' })
  jobTitle: string = '';

  @property()
  href: string = '';

  @property({attribute: 'button-label'})
  buttonLabel: string = '';

  @property()
  size: 'default' | 'small' = 'default';

  @property()
  variant: 'default' | 'reverse' = 'default';

  static get styles() {
    return personalAdvisingStyles;
  }

  firstUpdated() {
      const slot = this.shadowRoot!.querySelector('slot[name="image"]') as HTMLSlotElement;
      if (!slot || slot.assignedNodes().length === 0) {
        (this.shadowRoot!.querySelector('.person__container') as HTMLSlotElement).classList.add('no-img');
      }
    
  }

  render() {
    return html`
      <div class="personal-advising__wrapper personal-advising__wrapper--${this.size} personal-advising--${this.variant}">
        ${this.renderHeadline('hide-on-desktop')}
        ${this.renderIntroduction('hide-on-desktop')}
          <div class="personal-advising__body-wrapper">
            <div class="person__container">
              <div class="image-container">
                <div class="half-circle-small"></div>
                <div class="half-circle"></div>
                <slot class="personal-advising__image" name="image"></slot>
              </div>
              <div class="personal-advising__person-info">
                ${this.renderName()}
                ${this.renderJobTitle()}
              </div>
            </div>
            <div class="personal-advising__body-headline-wrapper">
              ${this.renderHeadline('hide-on-mobile')}
              <div class="personal-advising__body-button-wrapper">
                ${this.renderIntroduction('hide-on-mobile')}
                <div>
                  <slot></slot>
                </div>
                ${this.renderLink()}
            </div>
          </div>
         
        </div>
      </div>
    `;
  }

  renderHeadline(hideClass: string) {
    return this.heading
        ? html`<p class="personal-advising__headline ${hideClass}">${this.heading}</p>`
        : '';
  }

  renderIntroduction(hideClass: string) {
    return this.introduction
        ? html`<div class="personal-advising__introduction ${hideClass}">${unsafeHTML(this.introduction)}</div>`
        : '';
  }

  renderLink() {
    return this.href && this.buttonLabel
        ? html`<div>
          <sbk-button href=${this.href}>${this.buttonLabel}</sbk-button>
        </div>`
        : '';
  }

  renderName() {
    return this.personName
      ? html`<p class="personal-advising__name">${this.personName}</p>`
      : '';
  }

  renderJobTitle() {
    return this.jobTitle
      ? html`<p class="personal-advising__job-title">${this.jobTitle}</p>`
      : '';
  }

}