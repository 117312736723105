import {customElement, state} from 'lit/decorators.js';
import {html, LitElement, nothing} from 'lit';

// eslint-disable-next-line
declare const TYPO3: any;

@customElement('sbk-image-sources')
export class SbkImageSources extends LitElement {

  @state()
  sources: string[] = [];

  connectedCallback() {
    super.connectedCallback();
    this.initializeImageSourceContainer();
  }

  protected createRenderRoot() {
    return this;
  }

  render() {
    if (!this.sources.length) {
      return html`${nothing}`;
    }
    return html`
      <div class="element-container">
        <p>${TYPO3.lang.imagesource}\xa0${Array.from(new Set(this.sources)).join(', ')}</p>
      </div>
    `;
  }

  initializeImageSourceContainer() {
    const images = Array.from(document.querySelectorAll('img'))

    images.forEach((item: HTMLImageElement) => {
      const title = item.dataset.title ? item.dataset.title : ''
      const publisher = item.dataset.publisher ? item.dataset.publisher : ''
      const source = item.dataset.source ? item.dataset.source : ''

      if (publisher || source) {
        const divider = publisher && source ? ' / ' : ''
        this.sources.push(title + publisher + divider + source)
      }
    })
  }
}