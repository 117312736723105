import { LitElement, html } from 'lit';
import { property } from 'lit/decorators.js';
import { customElement } from 'lit/decorators/custom-element.js';
import { style } from './form-step-item.styles';

@customElement('sbk-form-step-item')
export class SbkFormStepItem extends LitElement {
    @property({ type: Boolean, reflect: true }) active = false;
    @property({ type: Boolean, reflect: true }) done = false;
    @property({ type: Boolean, reflect: true }) disabled = false;
    @property({ type: String }) label = '';
    firstUpdated() {
      this.setAttribute('tabindex', '0');
    }
    static get styles() {
        return style;
      }
  
    render() {
      return html`
          <div class="form-step-item${this.active ? ' form-step-item--active' : ''}${this.done ? ' form-step-item--done' : ''}${this.disabled ? ' form-step-item--disabled' : ''}">
              <div class="button"><slot></slot></div>
              <span class="text">${this.label}</span>
          </div>
      `;
    }
}
