import { customElement } from 'lit/decorators.js';
import { LitElement } from "lit";
import './summary-page.scss';

@customElement('sbk-summary-page')
export class SbkSummaryPage extends LitElement {
    createRenderRoot() {
        return this;
    }
}
