import { customElement, property } from 'lit/decorators.js';
import {html, LitElement} from "lit";
import {selectOptionStyles} from "@/components/select-option/select-option.styles";

/**
 * An example element.
 *
 * @slot - This element has a slot
 */
@customElement('sbk-select-option')
export class SbkSelectOption extends LitElement {

    static shadowRootOptions = {...LitElement.shadowRootOptions, delegatesFocus: true};

    @property()
    description: string = '';

    @property()
    value: string = '';

    @property({type: Boolean})
    disabled = false;

    @property({type: Boolean})
    selected = false;

    connectedCallback() {
        super.connectedCallback();
        this.setAttribute('aria-selected', this.selected ? 'true' : 'false');
    }

    static get styles() {
        return [
            selectOptionStyles
        ];
    }

    render() {
        return html`
            <div @mousedown=${this._selectItem} @touchdown=${this._selectItem} id="option" class="select-option${this.value ? '' : ' select-option--empty-option'}">
                <slot></slot>
                ${this.descriptionTemplate()}
            </div>
        `;
    }

    descriptionTemplate() {
        if (!this.description) {
            return '';
        }
        return html`
            <div class="select-option__description">${this.description}</div>
        `;
    }

    _selectItem() {
        const selectItemEvent = new CustomEvent('selectItem', {
            detail: { value: this.value },
            bubbles: true,
        });
        this.dispatchEvent(selectItemEvent);
    }
}
