import { css, unsafeCSS } from 'lit';
import {sbkCoreBreakpointMd, sbkCoreBreakpointLg} from "@/tokens/variables";

const breakpointMd = unsafeCSS(`${sbkCoreBreakpointMd}`);
const breakpointLg = unsafeCSS(`${sbkCoreBreakpointLg}`);

export const personalAdvisingStyles = css`
    :host {
        display: block;
    }

    * {
        box-sizing: border-box;
    }

    .personal-advising__wrapper {
        display: flex;
        flex-direction: column;
        gap: var(--sbkPersonalAdvisingSpacingSmallScrGap);
        font: var(--sbkPersonalAdvisingTypographySmallScreenBody);
        @media all and (min-width: ${breakpointLg}) {
            font: var(--sbkPersonalAdvisingTypographyLargeScreenBody);
        }
    }

    .personal-advising__wrapper--small {

        @media all and (min-width: ${breakpointMd}) {
            .person__container {
                width: 100%;
            }

            .personal-advising__body-headline-wrapper {
                padding: 0;
                width: 100%;
            }

            .personal-advising__job-title, .personal-advising__name {
                text-align: left;
            }
        }

        .personal-advising__body-wrapper {
            flex-direction: column;
        }

        .hide-on-desktop {
            display: block;
        }

        .hide-on-mobile {
            display: none;
        }
    }

    .person__container.no-img {
        display: none;
    }

    .personal-advising__body-wrapper {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        gap: var(--sbkPersonalAdvisingSpacingSmallScrGap);

        @media all and (min-width: ${breakpointMd}) {
            flex-direction: row;
        }
    }

    .personal-advising--reverse .personal-advising__body-wrapper {
        @media all and (min-width: ${breakpointMd}) {
            flex-direction: row-reverse;
            .personal-advising__job-title, .personal-advising__name {
                text-align: right;
            }
        }
    }

    .personal-advising__body-button-wrapper {
        display: flex;
        flex-direction: column;
        gap: var(--sbkPersonalAdvisingSpacingSmallScrGap);

        @media all and (min-width: ${breakpointLg}) {
            gap: var(--sbkPersonalAdvisingSpacingLargeScrGapTextButton);
        }
    }

    ::slotted(img) {
        clip-path: circle(50% at 50% 50%);
        aspect-ratio: 1;
        object-fit: contain;
    }

    .person__container {
        max-width: var(--sbkPersonalAdvisingSizingViewportSmImageMaxWidth);
        @media all and (min-width: ${breakpointMd}) {
            width: calc(100% / 3);
            max-width: unset;
        }
    }

    .image-container {
        position: relative;
        width: fit-content;
        height: fit-content;
        overflow: hidden;
    }

    .half-circle {
        position: absolute;
        width: 100%;
        height: 100%;
        margin: auto;
        background: linear-gradient(180deg, var(--sbkCoreColorBlue500) 50%, transparent calc(50% + 1px));
        border-radius: 100% 100% 0 0;
        transform: rotate(200deg);
    }

    .half-circle-small {
        position: absolute;
        width: 50%;
        height: 50%;
        margin: auto;
        left: 25%;
        bottom: 25%;
        background: linear-gradient(180deg, var(--sbkCoreColorViolet500) 50%, transparent calc(50% + 1px));
        border-radius: 50% 50% 0 0;
        transform: rotate(20deg);
    }


    .personal-advising__body-headline-wrapper {
        display: flex;
        flex-direction: column;
        gap: var(--sbkPersonalAdvisingSpacingLargeScrGapHeadlineText);
        padding: 0;
        max-width: var(--sbkCoreContentAreaBodyMaxWidth);
        @media all and (min-width: ${breakpointMd}) {
            padding: var(--sbkPersonalAdvisingSpacingLargeScrInsetTop) 0 0;
            width: calc(100% / 12 * 7);
        }
    }

    .personal-advising__headline {
        margin: 0;
        font: var(--sbkPersonalAdvisingTypographySmallScreenHeadline);
        @media all and (min-width: ${breakpointLg}) {
            font: var(--sbkPersonalAdvisingTypographyLargeScreenHeadline);
        }
    }

    .hide-on-desktop {
        display: block;

        @media all and (min-width: ${breakpointMd}) {
            display: none;
        }
    }

    .hide-on-mobile {
        display: none;

        @media all and (min-width: ${breakpointMd}) {
            display: block;
        }
    }
    
    .personal-advising__introduction {
        p {
            margin: 0;
        }
        &.hide-on-desktop {
            display: block;

            @media all and (min-width: ${breakpointMd}) {
                display: none;
            }
        }

        &.hide-on-mobile {
            display: none;

            @media all and (min-width: ${breakpointMd}) {
                display: block;
            }
        }
    }

    .personal-advising__job-title,
    .personal-advising__name {
        margin: 0;
        padding: 0;
        font: var(--sbkPersonalAdvisingTypographySmallScreenAnnotation);
        @media all and (min-width: ${breakpointLg}) {
            font: var(--sbkPersonalAdvisingTypographyLargeScreenAnnotation);
        }
    }

    .personal-advising__person-info {
        margin-top: var(--sbkPersonalAdvisingSpacingSmallScrGapImageAnnotation);
        @media all and (min-width: ${breakpointLg}) {
            margin-top: var(--sbkPersonalAdvisingSpacingLargeScrGapImageAnnotation);
        }
    }
`;
