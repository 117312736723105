import {css, unsafeCSS} from "lit";
import {sbkCoreBreakpointLg} from "@/tokens/variables";

const breakpointLg = unsafeCSS(`${sbkCoreBreakpointLg}`);

export const mapStyles = css`
    :host {
        display: block;
    }

    #map {
        border-radius: var(--sbkBannerBoxBorderRadiusLg);

        .gm-style-iw-d {
            font: var(--sbkTypographyContentSmallScrBodyRegularXs);
            @media all and (min-width: ${breakpointLg}) {
                font: var(--sbkTypographyContentLargeScrBodyRegularXs);
            }

            p {
                margin: 0 0 var(--sbkTypographySmallScr2xs);
            }
            
            .section--no-padding {
                margin: 0 0 var(--sbkTypographySmallScr2xs);
                p {
                    margin: 0;
                }
            }
        }
    }
`;
