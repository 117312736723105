import {css, unsafeCSS} from 'lit';
import {sbkCoreBreakpointLg} from '@/tokens/variables.js'

const breakpointLg = unsafeCSS(`${sbkCoreBreakpointLg}`);


export const addressInputStyles = css`
  * {
    box-sizing: border-box;
  }

  #annotation {
    margin-bottom: var(--sbkCoreSpacingMd);
  }
  
  fieldset {
    border: 0;
    padding: 0;
    margin: 0;
    min-width: 0;
  }
  
  .row {
    display: flex;
    gap: 1rem;

    .col-md-4 {
      width: 50%;
      @media all and (min-width: ${breakpointLg}) {
        width: calc(100% / 3);
      }
    }
    
    .col-md-8 {
      width: 50%;
      @media all and (min-width: ${breakpointLg}) {
        width: calc(100% * 2/3);
      }
    }
  }
`;