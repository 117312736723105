import {customElement, property} from 'lit/decorators.js';
import {SbkRoundButton} from "@/components/round-button/round-button";
import {html} from "lit";

@customElement('sbk-pagination-item')
export class SbkPaginationItem extends SbkRoundButton {

    @property({type: Number})
    page: number = 0;

    @property()
    variant: 'default' | 'icon-only' | 'text' = 'text';

    @property({type: Boolean, attribute: 'is-ajax'})
    isAjax = false;

    constructor() {
        super();
        this.size = 'medium';
    }

    connectedCallback() {
        super.connectedCallback();
        if (this.page) {
            this.innerText = String(this.page);
        }
        if (this.isAjax) {
            this.addEventListener('click', this._handleClick);
        }
    }

    disconnectedCallback() {
        super.disconnectedCallback();
        if (this.isAjax) {
            this.removeEventListener('click', this._handleClick);
        }
    }

    render () {
        return html`
            <li>
                ${super.render()}
            </li>
        `;
    }

    _handleClick(event: Event) {
        event.preventDefault();
        const pageChangeEvent = new CustomEvent('page-change', {
            detail: {
                value: this.href
            },
            bubbles: true,
            composed: true,
        })
        this.dispatchEvent(pageChangeEvent);
    }
}