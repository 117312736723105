import { css, unsafeCSS } from 'lit';
import {sbkCoreBreakpointLg, sbkCoreBreakpointMd, sbkCoreBreakpointXl} from "@/tokens/variables";

const breakpointMd = unsafeCSS(`${sbkCoreBreakpointMd}`);
const breakpointLg = unsafeCSS(`${sbkCoreBreakpointLg}`);
const breakpointXl = unsafeCSS(`${sbkCoreBreakpointXl}`);

export const style = css` 
    .form-steps {
        display: flex;
        margin-bottom: var(--sbkCoreGridSmGutter);

        @media (min-width: ${breakpointMd}) {
            margin-bottom: var(--sbkCoreGridMdGutter);
        }
        @media (min-width: ${breakpointLg}) {
            margin-bottom: var(--sbkCoreGridLgGutter);
        }
        @media (min-width: ${breakpointXl}) {
            margin-bottom: var(--sbkCoreGridXlGutter);
        }
  }
`;