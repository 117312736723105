import {css, unsafeCSS} from "lit";
import {sbkCoreBreakpointLg} from "@/tokens/variables";

const breakpointLg = unsafeCSS(`${sbkCoreBreakpointLg}`);

export const teaserLinkStyles = css`

    :host {
        display: flex;
        flex-direction: row;
        margin-bottom: var(--sbkTeaserLinkSpacingLargeScrMarginBottom);
    }

    sbk-icon {
        margin-right: var(--sbkTeaserLinkSpacingSmallScrIconGap);

        @media all and (min-width: ${breakpointLg}) {
            margin-top: 8px;
            margin-right: var(--sbkTeaserLinkSpacingLargeScrIconGap);
        }
    }


   sbk-headline-link, .headline {
        display: inline-block;
        margin-bottom: var(--sbkTeaserLinkSpacingSmallScrTextGap);
        margin-top: var(--sbkTeaserLinkSpacingSmallScrHeadlineLinkMarginTop);
       font: var(--sbkHeadlineLinkTypographySmallScrBoldMd);

        @media all and (min-width: ${breakpointLg}) {
            font: var(--sbkHeadlineLinkTypographyLargeScrBoldMd);
            margin-bottom: var(--sbkTeaserLinkSpacingLargeScrTextGap);
            margin-top: var(--sbkTeaserLinkSpacingLargeScrHeadlineLinkMarginTop);
        }
   }

   .teaser-link__body {
        font: var(--sbkTeaserLinkTypographyBodySmallScr);

        @media all and (min-width: ${breakpointLg}) {
            font: var(--sbkTeaserLinkTypographyBodyLargeScr);
        }
       
       ::slotted(*) {
           margin: 0 !important;
       }
   }
`;